import { useRef, useState } from "react";
import {
  extractMainPropertyData,
  getCheckoutLink,
  HotelsComAmenities,
  HotelsComMealPlan,
  HotelsComRegion,
  HotelsComSearch,
  HotelsComSearchQueryParams,
  HotelsComSearchRegions,
  PropertyMainData,
} from "../../../assets/Services/metasearch";
import { debounce } from "lodash";
import IconNew from "../../../assets/TakyonDesignSystem/components/Icon/Icon";
import Skeleton from "react-loading-skeleton";
import Stars from "../../../assets/TakyonDesignSystem/components/Stars/Stars";
import Button from "../../../assets/TakyonDesignSystem/components/Button/Button";
import logoSvgWhite from "../../../assets/TakyonDesignSystem/images/TakyonLight.svg";

export function RegionInput({
  onChange,
}: {
  onChange(params: { target: { name: string; value: string } }): void;
}) {
  const [query, setQuery] = useState(""); // Manage input value
  const [regionResults, setRegionResults] = useState<HotelsComRegion[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const search = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setIsLoading(true);

    const searchParams = {
      query: e.target.value,
      locale: "it_IT",
      domain: "IT",
    };

    try {
      const data = await HotelsComSearchRegions(searchParams);
      setRegionResults(data.data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const searchCallbackDebounced = useRef(debounce(search, 1000));

  return (
    <div className="position-relative">
      {/* Region Results Dropdown */}
      {regionResults.length || isLoading ? (
        <div
          style={{ top: "120%", zIndex: "100", width: "150%" }}
          className="position-absolute bg-white border p-2 rounded"
        >
          {isLoading ? "loading..." : ""}
          {regionResults.map((region, key) => {
            return (
              <div
                key={region.gaiaId} // Add a unique key for list items
                onClick={() => {
                  // Update the query with the selected region's display name
                  setQuery(region.regionNames.displayName);

                  // Clear the dropdown
                  setRegionResults([]);

                  // Trigger onChange with the selected region's gaiaId
                  onChange({
                    target: { name: "region_id", value: region.gaiaId },
                  });
                }}
                className="my-2 cursor-pointer"
              >
                {region.regionNames.displayName}

                {key !== regionResults.length - 1 ? <hr /> : null}
              </div>
            );
          })}
        </div>
      ) : null}

      {/* Input Field */}
      <input
        style={{ border: "none" }}
        value={query}
        onChange={(e) => {
          // Only run this when the user types, not when clicking a region
          setQuery(e.target.value);
          setIsLoading(true);
          searchCallbackDebounced.current(e);
        }}
        id="region_id"
        name="region_id"
        className="form-control"
        required
      />
    </div>
  );
}

export default function Metasearch() {
  const [result, setResult] = useState<PropertyMainData[]>();
  const [searchParams, setSearchParams] = useState<HotelsComSearchQueryParams>({
    region_id: 0,
    checkin_date: "",
    checkout_date: "",
    adults_number: 1,
    sort_order: "REVIEW",
    locale: "it_IT",
    domain: "IT",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;

    if (name === "checkin_date" || name === "checkout_date") {
      const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      if (!dateRegex.test(value)) {
        alert("Invalid date format. Please use yyyy-MM-dd.");
        return;
      }
    }

    if (name === "children_ages") {
      setSearchParams((prev) => ({
        ...prev,
        [name]: value
          .split(",")
          .map((age) => age.trim())
          .join(","),
      }));
    } else {
      setSearchParams((prev) => ({
        ...prev,
        [name]:
          name === "region_id" ||
          name === "adults_number" ||
          name === "price_min" ||
          name === "price_max"
            ? +value
            : value,
      }));
    }
  };

  const search = async () => {
    setIsLoading(true);

    setResult(undefined);

    try {
      const data = await HotelsComSearch(searchParams);

      console.log(data);

      setResult(data.map((p) => extractMainPropertyData(p)));
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  return (
    <div className="bg-white">
      <div className="container d-flex flex-column align-items-center justify-content-center">
        <br />
        <br />
        <br />
        <br />
        <div className="w-100">
          <form>
            <div className="">
              <div className="d-flex gap-2">
                <div className="w-100 border rounded">
                  <label htmlFor="region_id" className="form-label px-2">
                    Location:
                  </label>
                  <RegionInput onChange={handleChange as any} />
                  <input
                    hidden
                    type="number"
                    id="region_id"
                    name="region_id"
                    className="form-control"
                    value={searchParams.region_id || ""}
                    // onChange={handleChange}
                    required
                  />
                </div>

                <div className="d-flex w-100 border rounded">
                  <div className="w-100">
                    <label htmlFor="checkin_date" className="form-label px-2">
                      Check-in Date:
                    </label>
                    <input
                      style={{ border: "none" }}
                      type="date"
                      id="checkin_date"
                      name="checkin_date"
                      className="form-control"
                      value={searchParams.checkin_date || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="w-100">
                    <label htmlFor="checkout_date" className="form-label px-2">
                      Check-out Date:
                    </label>
                    <input
                      style={{ border: "none" }}
                      type="date"
                      id="checkout_date"
                      name="checkout_date"
                      className="form-control"
                      value={searchParams.checkout_date || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="d-flex w-100 border rounded">
                  <div className="w-100">
                    <label htmlFor="adults_number" className="form-label px-2">
                      Number of Adults:
                    </label>
                    <input
                      style={{ border: "none" }}
                      type="number"
                      id="adults_number"
                      name="adults_number"
                      className="form-control"
                      min="1"
                      value={searchParams.adults_number || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="w-100">
                    <label htmlFor="children_ages" className="form-label px-2">
                      Children Ages:
                    </label>
                    <input
                      style={{ border: "none" }}
                      type="text"
                      id="children_ages"
                      name="children_ages"
                      className="form-control"
                      placeholder="i.e.: 4,7,0"
                      value={searchParams.children_ages || ""}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div
                  onClick={() => !isLoading && search()}
                  className="bg-primary p-3"
                  style={{
                    height: "60px",
                    width: "60px",
                    aspectRatio: "1/1",
                    borderRadius: "100%",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {!isLoading ? <IconNew icon="search" size={50} /> : "..."}
                </div>
              </div>
            </div>
          </form>
        </div>
        <br />
        <div className="d-flex w-100 gap-4">
          <div
            style={{ width: "25%", borderRight: "1px solid lightgray" }}
            className="rounded bg-white p-3"
          >
            <p className="h3">Filter by</p>
            <br />

            <div>
              <p className="bodytext-lg mb-2">Price per night</p>
              <div className="d-flex gap-3">
                <div className="border rounded pt-1">
                  <label className="px-3 medium bodytext-sm" htmlFor="">
                    Min
                  </label>
                  <input
                    style={{ border: "none" }}
                    name="price_min"
                    onChange={handleChange}
                    type="number"
                  />
                </div>
                <div className="border rounded pt-1">
                  <label className="px-3 medium bodytext-sm" htmlFor="">
                    Max
                  </label>
                  <input
                    style={{ border: "none" }}
                    name="price_max"
                    onChange={handleChange}
                    type="number"
                  />
                </div>
              </div>
            </div>
            <hr />
            <div>
              <p className="bodytext-lg mb-2">Amenities</p>

              {Object.keys(HotelsComAmenities).map((amenity, key) => {
                return (
                  <div className="d-flex align-items-center gap-3" key={key}>
                    <div>
                      <input
                        onChange={(e) => {
                          if (e.target.checked)
                            setSearchParams({
                              ...searchParams,
                              amenities: (searchParams.amenities =
                                (searchParams.amenities ?? "") + `${amenity},`),
                            });
                          else {
                            setSearchParams({
                              ...searchParams,
                              amenities: searchParams.amenities?.replaceAll(
                                `${amenity},`,
                                ""
                              ),
                            });
                          }
                        }}
                        type="checkbox"
                      />
                    </div>
                    <label
                      style={{ textTransform: "capitalize" }}
                      htmlFor={amenity}
                    >
                      {amenity.toLowerCase().replaceAll("_", " ")}
                    </label>
                  </div>
                );
              })}
            </div>
            <hr />
            <div>
              <p className="bodytext-lg mb-2">Meal Plan</p>

              {Object.keys(HotelsComMealPlan).map((plan, key) => {
                return (
                  <div className="d-flex align-items-center gap-3" key={key}>
                    <div>
                      <input
                        onChange={(e) => {
                          if (e.target.checked)
                            setSearchParams({
                              ...searchParams,
                              meal_plan: (searchParams.meal_plan =
                                (searchParams.meal_plan ?? "") + `${plan},`),
                            });
                          else {
                            setSearchParams({
                              ...searchParams,
                              meal_plan: searchParams.meal_plan?.replaceAll(
                                `${plan},`,
                                ""
                              ),
                            });
                          }
                        }}
                        type="checkbox"
                      />
                    </div>

                    <label
                      style={{ textTransform: "capitalize" }}
                      htmlFor={plan}
                    >
                      {plan.toLowerCase().replaceAll("_", " ")}
                    </label>
                  </div>
                );
              })}
            </div>

            <br />
            <br />
            <br />
            <div className="text-center ">... MORE FILTERS COMING SOON ...</div>
            <br />
            <br />
          </div>
          <div style={{ width: "75%" }}>
            {isLoading ? (
              <>
                <Skeleton height={250} count={5} />
              </>
            ) : null}

            {result && (
              <div className="">
                <p className="bodytext">
                  More than {result.length} Results found:
                </p>
                <br />
                <div className="d-flex flex-column gap-3">
                  {result.map((property, index) => (
                    <div className="" key={index}>
                      <div
                        style={{
                          boxShadow: property._collection
                            ? "0px 0px 20px green"
                            : "0px 0px 10px lightgray",
                        }}
                        className={`card d-flex flex-row h-100 cursor-pointer`}
                        onClick={() => {
                          window.open("/metasearch/" + property.id);

                          // if (!property._collection) return;
                          // navigateToHotelWebsite({
                          //   collectionId: property._collection?._id,
                          //   checkin_date: searchParams.checkin_date,
                          //   checkout_date: searchParams.checkout_date,
                          //   adults_number: searchParams.adults_number ?? 1,
                          // });
                        }}
                      >
                        <img
                          src={property.propertyImage}
                          alt={"Hotel"}
                          style={{
                            width: "200px",
                            height: "200px",
                            objectFit: "cover",
                            borderTopLeftRadius: "4px",
                            borderBottomLeftRadius: "4px",
                          }}
                        />
                        <div className="card-body d-flex">
                          <div className="d-flex flex-column">
                            <h5 className="card-title bodytext-xl">
                              {property.name}
                            </h5>

                            <Stars stars={property.starRating} />
                            <p className="card-text">{property.location}</p>

                            <br />
                            <div className="mt-auto">
                              <p className="card-text">
                                <span className="bg-black p-1 px-2 rounded text-white mx-1">
                                  {property.rating.score}
                                </span>
                                <span className="bodytext-sm">
                                  ({property.rating.totalReviews} reviews)
                                </span>
                              </p>
                            </div>
                            <br />

                            <p
                              style={{
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textWrap: "nowrap",
                              }}
                              className="bodytext"
                            >
                              Lorem ipsum dolor sit amet consectetur adipisicing
                              elit...
                            </p>
                          </div>

                          <div className="d-flex flex-column align-items-end justify-content-end w100 gap-2">
                            {property._collection ? (
                              <div
                                style={{ position: "relative" }}
                                className="h100 border rounded d-flex p-2 pt-4 justify-content-between flex-column bg-primary-light"
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    top: "-10px",
                                    left: "-10px",
                                  }}
                                  className="bg-black text-white p-1 rounded bodytext"
                                >
                                  Best price guaranteed by{" "}
                                  <img src={logoSvgWhite} alt="" style={{height: '17px'}} />
                                </div>
                                <div>
                                  <p className="bodytext medium">
                                    {property._collection.census.website
                                      ?.replace("www.", "")
                                      .replace("https://", "")
                                      .replace("http://", "")}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center gap-5">
                                  <p className="h3">
                                    € {(property.price * 0.9).toFixed(2)}
                                  </p>

                                  <Button
                                    onClick={() => {
                                      let url =
                                        property?._collection?.census.website;

                                      if (!url?.includes("http"))
                                        url = "https://" + url;

                                      window.open(url, "_blank");
                                    }}
                                    className="mb-1"
                                    text="Book Directly >"
                                  />
                                </div>
                              </div>
                            ) : null}

                            <div className="bg-light-grey rounded p-2 px-4 flex-column">
                              <p className="bodytext">OTA price</p>
                              <p className="bodytext-lg medium">
                                € {property.price}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
}
